<template>
  <div
    class="bg-white flex flex-col text-14px overflow-auto h-screen w-full mt-3"
  >
    <div class="font-montserrat text-14px font-bold text-gray-lp-300">
      Koleksi Digital Photocard
    </div>

    <div class="flex flex-col md:flex-row mt-6">
      <div class="flex flex-col">
        <div
          class="border-b border-gray-lp-400 font-montserrat font-semibold text-20px text-black-lp-200 py-3"
        >
          Gold Digital Photocard
        </div>
        <photo-card
          v-model="collectibleCardGold"
          customClass="w-13/20 rounded-lg"
          :isLoading="isLoading"
        />
      </div>
      <div class="w-5 hidden md:flex"></div>
      <div class="flex flex-col">
        <div
          class="border-b border-gray-lp-400 font-montserrat font-semibold text-20px text-black-lp-200 py-3"
        >
          Hologram Digital Photocard
        </div>
        <photo-card
          v-model="collectibleCardHologram"
          customClass="w-13/20 rounded-lg"
          :isLoading="isLoading"
        />
      </div>
    </div>
    <Accordion
      label="Silver Digital Photocard"
      class="font-montserrat font-semibold text-20px text-black-lp-200 mt-6"
      border-header="border-b"
      mainBorderEdge=""
      border-body=""
      custom-class="px-0"
      :chevrons="['chevron-up', 'chevron-down']"
      :default-opened="true"
    >
      <div class="grid grid-cols-5 grid-col-mobile gap-4">
        <photo-card
          v-for="(card, index) in photoCards"
          :key="index"
          v-model="collectibleCardSilver[index]"
          customClass="cursor-pointer w-13/20 rounded-lg"
          :isUsingTotal="true"
          :isLoading="isLoading"
        />
      </div>
    </Accordion>
    <Accordion
      label="S&K Kirim & Menangkan"
      class="text-16px mb-5 mt-6 text-black-lp-200"
      :chevrons="['chevron-up', 'chevron-down']"
      custom-width-header="w-10/12"
    >
      <div class="p-6" v-html="tncFromRollout"></div>
    </Accordion>
  </div>
</template>

<script>
import PhotoCard from "@/app/ui/views/send-and-win/component/photo-card.vue";
import { Options, Vue } from "vue-class-component";
import Accordion from "@/app/ui/components/accordion/index.vue";
import { CollectibleCardController } from "@/app/ui/controllers/CollectibleCardController";
import { FlagsSnKGamification } from "@/feature-flags/flag-SnK-Gamification";

@Options({
  components: { Accordion, PhotoCard },
})
export default class SendAndWin extends Vue {
  photoCards = Array(10).fill({});

  get tncFromRollout() {
    return FlagsSnKGamification.s_and_k_gamification.getValue();
  }

  created() {
    this.onRequest();
  }

  get collectibleCardGold() {
    return CollectibleCardController.collectibleItemEntities.data.gold[0];
  }

  get collectibleCardHologram() {
    return CollectibleCardController.collectibleItemEntities.data.hologram[0];
  } 

  get collectibleCardSilver() {
    return CollectibleCardController.collectibleItemEntities.data.silver.sort(
      (a, b) => a.imageId - b.imageId
    );
  }

  get isLoading() {
    return CollectibleCardController.isLoading;
  }

  get isError() {
    return CollectibleCardController.isError;
  }

  get errorCause() {
    return CollectibleCardController.errorCause;
  }

  onRequest() {
    CollectibleCardController.getCollectibleItem();
  }
}
</script>
<style scoped>
@media (max-width: 768px) {
  .grid-col-mobile {
    @apply grid-cols-2;
  }
}

.send-and-win-ol {
  padding-inline-start: 35px;
  line-height: 24px;
}

.send-and-win-ol ::v-deep .send-and-win-hyperlink {
  @apply underline;
}

.send-and-win-ol ::v-deep .send-and-win-hyperlink:hover {
  @apply bg-blue-lp-600;
}
</style>
