
import { prop, Vue } from "vue-class-component";
import { downloadFile } from "@/app/infrastructures/misc/Utils";

class Props {
  modelValue = prop<any>({
    required: true
  });
  customClass = prop<string>({
    default: "",
    type: String
  });
  isUsingTotal = prop<boolean>({
    default: false,
    type: Boolean
  });
  isLoading = prop<boolean>({
    default: false,
    type: Boolean
  });
}

export default class PhotoCard extends Vue.with(Props) {
  isImageStillLoading = true;
  isImageError = false;

  onImageLoaded() {
    this.isImageStillLoading = false;
    this.isImageError = false;
  }

  onImageError() {
    this.isImageStillLoading = false;
    this.isImageError = true;
  }

  async downloadImage() {
    const imageUrl = this.modelValue?.imageUrl;
    if (imageUrl) {
      if (imageUrl) {
          if (this.modelValue?.grade === 'SILVER') {
              downloadFile(this.localPhotoCard, `CEOCARD_${this.modelValue?.grade}_${this.modelValue?.imageId}`, ".jpg");
          } else {
              downloadFile(this.localPhotoCard, `CEOCARD_${this.modelValue?.grade}`, ".jpg");
          }
      }
    }
  }

  goToDetailPage() {
    this.$router.push(`/send-and-win/${this.modelValue?.imageId}`);
  }

  get localPhotoCard() {
    switch (this.modelValue?.imageUrl) {
      case "gold":
        return `/img/icons/gold.jpg`;
      case "hologram":
        return `/img/icons/hologram.jpg`;
      case "silver_01":
        return `/img/icons/silver_01.jpg`;
      case "silver_02":
        return `/img/icons/silver_02.jpg`;
      case "silver_03":
        return `/img/icons/silver_03.jpg`;
      case "silver_04":
        return `/img/icons/silver_04.jpg`;
      case "silver_05":
        return `/img/icons/silver_05.jpg`;
      case "silver_06":
        return `/img/icons/silver_06.jpg`;
      case "silver_07":
        return `/img/icons/silver_07.jpg`;
      case "silver_08":
        return `/img/icons/silver_08.jpg`;
      case "silver_09":
        return `/img/icons/silver_09.jpg`;
      case "silver_10":
        return `/img/icons/silver_10.jpg`;
      default: {
        return "";
      }
    }
  }

}
