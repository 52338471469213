
/* eslint-disable @typescript-eslint/camelcase */
import { Options, Vue } from "vue-class-component";
import Skeleton from "@/app/ui/components/skeleton/index.vue";
import { downloadFile } from "@/app/infrastructures/misc/Utils";
import { CollectibleCardController } from "@/app/ui/controllers/CollectibleCardController";
import { AccountController } from "@/app/ui/controllers/AccountController";

@Options({
})

export default class DetailCardSendAndWin extends Vue {
    isImageStillLoading = true;
    isImageError = false;

    mounted() {
        this.resetDetailCollectibleItem();
        this.closeCollapseSidebar();
        this.getDetailCollectibleItem(Number(this.$route.params.id));
    }

    onImageLoaded() {
        this.isImageStillLoading = false;
        this.isImageError = false;
    }

    onImageError() {
        this.isImageStillLoading = false;
        this.isImageError = true;
    }

    goBack() {
        this.$router.push(`/send-and-win`);
    }

    async downloadImage() {
        const imageUrl = this.detailCollectibleCard?.imageUrl;
        if (imageUrl) {
            if (this.detailCollectibleCard.grade === 'SILVER') {
                downloadFile(this.localPhotoCard, `CEOCARD_${this.detailCollectibleCard.grade}_${this.detailCollectibleCard.imageId}`, ".jpg");
            } else {
                downloadFile(this.localPhotoCard, `CEOCARD_${this.detailCollectibleCard.grade}`, ".jpg");
            }
        }
    }

    get isLoading() {
        return CollectibleCardController.isLoading;
    }

    get detailCollectibleCard() {
        return CollectibleCardController.detailCollectibleItemCard;
    }

    getDetailCollectibleItem(imageId: number) {
        CollectibleCardController.getDetailCollectibleItem({imageId: imageId});
    }

    resetDetailCollectibleItem() {
        CollectibleCardController.resetDetailCollectibleItemCard();
    }

    get accountPhoneNumber() {
        return AccountController.accountData.phone_number
    }

    closeCollapseSidebar() {
        AccountController.setIsCollapseSidebar(true);
    }

    get localPhotoCard() {
        switch (this.detailCollectibleCard?.imageUrl) {
            case "gold":
            return `/img/icons/gold.jpg`;
            case "hologram":
            return `/img/icons/hologram.jpg`;
            case "silver_01":
            return `/img/icons/silver_01.jpg`;
            case "silver_02":
            return `/img/icons/silver_02.jpg`;
            case "silver_03":
            return `/img/icons/silver_03.jpg`;
            case "silver_04":
            return `/img/icons/silver_04.jpg`;
            case "silver_05":
            return `/img/icons/silver_05.jpg`;
            case "silver_06":
            return `/img/icons/silver_06.jpg`;
            case "silver_07":
            return `/img/icons/silver_07.jpg`;
            case "silver_08":
            return `/img/icons/silver_08.jpg`;
            case "silver_09":
            return `/img/icons/silver_09.jpg`;
            case "silver_10":
            return `/img/icons/silver_10.jpg`;
            default: {
            return "";
            }
        }
    }

}
