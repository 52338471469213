import {
  CollectibleItemCard,
  CollectibleItemData,
  CollectibleItemEntities
} from "@/domain/entities/CollectibleCard";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { container } from "tsyringe";
import { CollectibleCardPresenter } from "@/app/ui/presenters/CollectibleCardPresenter";

export interface CollectibleCardState {
  collectibleItemEntities: CollectibleItemEntities;
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
}

@Module({ namespaced: true, dynamic: true, store, name: "collectible-card" })
class CollectibleCardStore extends VuexModule implements CollectibleCardState {
  public collectibleItemEntities = new CollectibleItemEntities(
    new CollectibleItemData({
      gold: [],
      hologram: [],
      silver: []
    })
  );
  public errorCause = "";
  public isError = false;
  public isLoading = false;
  public detailCollectibleItemCard = new CollectibleItemCard({
      grade: "",
      imageId: 0,
      imageUrl: "",
      total: 0,
      claimStatus: false
    });

  @Action
  public async getCollectibleItem() {
    this.setLoading(true);
    const presenter = container.resolve(CollectibleCardPresenter);
    return presenter
      .getCollectibleItem()
      .then((res: CollectibleItemEntities) => {
        this.setCollectibleItemEntities(res);
        this.setErrorCause("");
        this.setError(false);
      })
      .catch((err: any) => {
        this.setError(true);
        this.setErrorCause(err.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public async getDetailCollectibleItem(params: { imageId: number }) {
    this.setLoading(true);
    const presenter = container.resolve(CollectibleCardPresenter);
    return presenter
      .getDetailCollectibleItem(params.imageId)
      .then((res: CollectibleItemCard) => {
        this.setDetailCollectibleItemCard(res);
        this.setErrorCause("");
        this.setError(false);
      })
      .catch((err: any) => {
        this.setError(true);
        this.setErrorCause(err.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Mutation
  public setCollectibleItemEntities(data: CollectibleItemEntities) {
    this.collectibleItemEntities = data;
  }

  @Mutation
  public setDetailCollectibleItemCard(data: CollectibleItemCard) {
    this.detailCollectibleItemCard = data;
  }

  @Mutation
  public resetDetailCollectibleItemCard() {
    this.detailCollectibleItemCard = new CollectibleItemCard({
      grade: "",
      imageId: 0,
      imageUrl: "",
      total: 0,
      claimStatus: false
    });
  }

  @Mutation
  public setLoading(status: boolean) {
    this.isLoading = status;
  }

  @Mutation
  public setError(status: boolean) {
    this.isError = status;
  }

  @Mutation
  public setErrorCause(cause: string) {
    this.errorCause = cause;
  }
}

export const CollectibleCardController = getModule(CollectibleCardStore);
